.Dashboard-Board--topics--Three{
    padding: 23px 32px;
    border: 3px solid transparent;
    border-right: none;
    border-left: none;
    display:grid;
    gap: 30px;
    grid-template-columns: repeat(4, 1fr);
}
.Dashboard-Board--topics--Three > *:first-child{
    grid-column: span 2;
}