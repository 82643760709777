.Dashboard{
    background-color: #E5E5E5;
    padding: 32px 0 80vh 0;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100%;
    box-sizing: border-box;
}
.Dashboard--list{
    display: grid;
    margin: auto auto;
    padding: 40px 0;
    border-radius: 10px;
}

.Dashboard--list-AdBanner {
    margin: 70px auto;
}

.Dashboard--list-add{
    justify-self: center;
    position: relative;
    height: 48px;
    border: none;
    background: transparent;
    width: 100%;
    padding: 0;
    cursor: pointer;
}
.Dashboard--list-add--white{
    background-color: #ffffff;
}
.Dashboard--list-add:hover,
.Dashboard--list-add:focus{
    border: none;
    box-shadow: none;
}
.Dashboard--list-add:before{
    content: '';
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    border-top: 2px dashed #21212122;
    transform: translateY(-50%);
    transition: border-top-color 50ms linear;
}
.Dashboard--list-add:hover:before{
    border-top-color: #212121;
}
.Dashboard--list-add:after{
    content: '';
    position: absolute;
    width: 32px;
    height: 32px;
    top: 50%;
    left: 50%;
    border: 2px solid #212121;
    background-color: #ffffff;
    transform: translate(-50%, -50%);
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../../img/plus-thick.svg");
    border-radius: 50%;
    transition: opacity 50ms linear;
}

@media (max-width: 1300px) {
    .Dashboard--list-add{
        justify-self: normal;
        min-width: 90%;
        max-width: 100%;
    }
    .Dashboard--list{
        min-width: 95%;
        max-width: 100%;
        margin: 0 auto;
    }
    .Dashboard--list-AdBanner {
        width: 95%;
    }
    .Dashboard--list-AdBanner {
        margin: 50px auto;
    }
}