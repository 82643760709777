.Navigator-Externals{
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100%;
}
.Navigator-Externals--top{
    background-color: var(--color-text);
    padding: calc(var(--font-size) / 2);
    display: grid;
}
.Navigator-Externals--list{
    overflow-y: scroll;
}
.Navigator-Externals-Single--edit{
    display: none;
}
.Navigator-Externals-Single--edit--open{
    display: grid;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.Navigator-Externals--add{
    background-position: center right calc(var(--font-size) / 4);
    background-size: 24px;
    background-repeat: no-repeat;
    background-image: url("../../../img/plus-thick.svg");
    padding-right: calc(var(--font-size) / 6);
}

@media (max-width: 1300px) {
    .Navigator-Externals--top{
        z-index: 1;
    }
    .Navigator-Externals--list{
        background-color: #fff;
        z-index: 1;
    }
}