.base-Page{
    display: grid;
    grid-template-columns: 1fr 400px;
    grid-template-rows: 100%;
    grid-gap: 4px;
    background-color: var(--color-text);
    height: 100%;
}
.base-Page--part{
    background-color: var(--backgrount-color);
}

@media (max-width: 1300px) {
    .base-Page{
        grid-template-columns: 1fr;
        grid-template-rows: 65% 35%;
    }
    .base-Page--part{
        min-width: 90%;
        max-width: 100%;
    }
}

