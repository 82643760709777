.Dashboard-Board-Slot--CardExtend{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto 1fr;
    gap: 0 30px;
    position: relative;
}
.Dashboard-Board-Slot--CardExtend:before{
    content: '';
    position: absolute;
    display: block;
    top: 20px;
    left: calc(50% + 15px);
    height: 10px;
    width: 20px;
    background-color: #1A1A1A;
}

.Dashboard-Board-Slot--CardExtend-name{
    font-family: var(--font-family-name);
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: #1A1A1A;
    text-decoration: none;
    margin-top: 34px;
}
.Dashboard-Board-Slot--CardExtend-category{
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 20px;
    color: #4FAE47;
    margin-top: 13px;
}
.Dashboard-Board-Slot--CardExtend-lead{
    font-family: var(--font-family-name);
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    color: #1A1A1A;
    margin-top: 27px;
}
.Dashboard-Board-Slot--CardExtend-poster{
    padding-top: calc(100% / 600 * 270);
    position: relative;
    grid-row: 1 / span 3;
    z-index: 0;
}
.Dashboard-Board-Slot--CardExtend-poster:before{
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 25%;
    z-index: 1;
    background: linear-gradient(180deg, rgba(26, 26, 26, 0) 0%, #1A1A1A 100%);
    mix-blend-mode: normal;
}
.Dashboard-Board-Slot--CardExtend-poster-img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    object-position: center;
    object-fit: cover;
    z-index: 0;
}