.Dashboard-Ad{
    font-family: var(--font-family-name);
    display: grid;
    gap: 22px;
    width: 1300px;
    max-width: 100%;
    margin: 70px auto;
    margin-bottom: 50px;
}
.Dashboard-Ad--title{
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #1A1A1A;
}
.Dashboard-Ad--title:before{
    content: '/ / ';
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    color: #4FAE47;
}
.Dashboard-Ad--title:after{
    content: ' / /';
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    color: #4FAE47;
}
.Dashboard-Ad--list{
    display: grid;
    gap: 20px;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
}
.Dashboard-Ad--item{
    background-color: #ffffff;
    display: grid;
    grid-template-rows: auto 1fr;
}
.Dashboard-Ad--item-poster{
    background-color: #FFE0B2;
    padding-top: calc(100% / 228 * 120);
}
.Dashboard-Ad--item-title{
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    padding: 10px;
    letter-spacing: -1px;
}

@media (max-width: 1300px) {
    .Dashboard-Ad{
        min-width: 95%;
        width: 0;
        max-width: 100%;
        margin: 20px auto;
    }
}