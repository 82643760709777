.Dashboard-Board-Slot--Card{
    display: grid;
    grid-template-rows: auto 1fr auto;
    gap: 10px;
    position: relative;
}

.Dashboard-Board-Slot--Card-name{
    font-family: var(--font-family-name);
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    text-decoration: none;
}
.Dashboard-Board-Slot--Card-category{
    font-family: var(--font-family-other);
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 20px;
    color: #4FAE47;
    align-self: end;
}
.Dashboard-Board-Slot--Card-lead{
    font-family: var(--font-family-name);
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 25px;
    color: #1A1A1A;
    margin-top: 27px;
}
.Dashboard-Board-Slot--Card-poster{
    padding-top: calc(100% / 290 * 160);
    position: relative;
    z-index: 0;
}
.Dashboard-Board-Slot--Card-poster:before{
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 25%;
    z-index: 1;
    background: linear-gradient(180deg, rgba(26, 26, 26, 0) 0%, #1A1A1A 100%);
    mix-blend-mode: normal;
}
.Dashboard-Board-Slot--Card-poster-img{
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    object-position: center;
    object-fit: cover;
}