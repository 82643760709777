.Dashboard-Board-Slot--LineExtend{
    display: grid;
    align-content: center;
    align-items: center;
    gap: 4px 30px;
    text-align: center;
}
.Dashboard-Board-Slot--LineExtend-category{
    font-weight: bold;
    font-size: 13px;
    line-height: 20px;
    color: #BBBBBB;
}
.Dashboard-Board-Slot--LineExtend-name{
    font-family: var(--font-family-name);
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    color: #000000;
    text-decoration: none;
}