.Search{
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100%;
}
.Search--form{
    display: grid;
    grid-template-columns: 1fr auto;
    padding: calc(var(--font-size) / 2);
    background-color: var(--color-text);
}
.Search--form-input:not([type]){
    border-radius: var(--border-radius) 0 0 var(--border-radius);
}
.Search--form-submit{
    height: 32px;
    width: 32px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 24px;
    background-image: url("../../../img/magnify.svg");
    border-radius: 0;
    border-right-color: transparent;
    cursor: pointer;
}
.Search--form-submit:last-child{
    border-radius: 0 var(--border-radius) var(--border-radius) 0;
}
.Search--form-submit--process{
    background-image: url("../../../img/spinner.svg");
}
.Search--topics{
    overflow-y: scroll;
    overflow-x: hidden;
}

@media (max-width: 1300px) {
    .Search--form{
        z-index: 0;
    }
    .Search--topics{
        display: flex;
        background-color: #fff;
        z-index: 1;
    }
    .Search--topics{
        overflow-x: scroll;
    }
}