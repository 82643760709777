.base-Loading{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #000000;
    display: grid;
    justify-content: center;
    align-content: center;
    justify-items: center;
    gap: 16px;
    user-select: none;
}
.base-Loading--spinner{
    width: 48px;
    height: 48px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('../../../img/spinner-big.svg');
}
.base-Loading--message{
    color: #ffffff;
    font-size: 18px;
}