.Dashboard-Board--topics--ThreeMediaExtend{
    padding: 23px 0;
    display:grid;
    gap: 30px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.Dashboard-Board--topics--ThreeMediaExtend > *:first-child{
    grid-row: span 2;
    grid-column: span 2;
}