.Dashboard-Board{
    background-color: #ffffff;
    display: grid;
    padding: 16px 0;
}
.Dashboard-Board----connectPrev .Dashboard-Board--title{
    visibility: hidden;
}
.Dashboard-Board-content{
    display: grid;
    grid-gap: var(--font-size);
    position: relative;
    grid-template-columns: 1fr auto;
    width: 1300px;
    max-width: 100%;
    justify-self: center;
}
.Dashboard-Board--title:not([qwe]){
    display: block;
    width: 100%;
    box-sizing: border-box;
    border-color: transparent;
    font-size: calc(var(--font-size) * 1.6);
}
.Dashboard-Board--title::placeholder{
    color: #e0e0e0;
}
.Dashboard-Board--controls{
    visibility: hidden;
    display: grid;
    grid-auto-flow: column;
    gap: var(--font-size);
    align-self: center;
    align-items: center;
    position: relative;
}
.Dashboard-Board--controls-button{
    width: 32px;
    height: 32px;
    box-sizing: border-box;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 24px;
}
.Dashboard-Board--controls-checkboxes{
    display: grid;
}
.Dashboard-Board--remove{
    background-image: url("../../../../img/close-thick.svg");
}
.Dashboard-Board--up{
    background-image: url("../../../../img/arrow-up-bold.svg");
}
.Dashboard-Board--down{
    background-image: url("../../../../img/arrow-down-bold.svg");
}
.Dashboard-Board--types-parent{
    display: grid;
    gap: calc(var(--font-size) / 2);
    grid-auto-flow: column;
    align-items: center;
}

.Dashboard-Board:hover .Dashboard-Board--controls{
    visibility: visible;
}
.Dashboard-Board--topics{
    grid-column: span 2;
}

@media (max-width: 1300px) {
    .Dashboard-Board-content{
        width: 0;
        min-width: 95%;
        max-width: 100%;
    }
    .Dashboard-Board{
        min-width: 90%;
        max-width: 100%;
    }
    .Dashboard-Board--title:not([qwe]){
        font-size: 16px;
    }
}