.Dashboard-Banner{
    height: 150px;
    background-color: #bdbdbd;
    display: grid;
    align-content: center;
    justify-content: center;
    font-size: 45px;
    text-transform: uppercase;
    width: 100%;
    max-width: 1300px;
    margin: 30px auto;
}

@media (max-width: 1300px) {
    .Dashboard-Banner{
        max-width: 1000px;
    }
}