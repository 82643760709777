.Dashboard-Board-Slot{
    display: grid;
    position: relative;
    z-index: 0;
}
.Dashboard-Board-Slot----over:not(.Dashboard-Board-Slot----empty){
    outline: #4FAE47 solid 4px;
    outline-offset: 4px;
}
.Dashboard-Board-Slot--empty{
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f3f3f3;
    align-content: center;
    justify-content: center;
    font-size: 38px;
    font-weight: bolder;
    text-transform: uppercase;
    z-index: 2;
}
.Dashboard-Board-Slot----empty .Dashboard-Board-Slot--empty{
    display: grid;
}
.Dashboard-Board-Slot----empty.Dashboard-Board-Slot----over .Dashboard-Board-Slot--empty{
    border: #4FAE47 solid 4px;
}
.Dashboard-Board-Slot--clear{
    position: absolute;
    top: 0;
    right: 0;
    height: 24px;
    width: 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 18px;
    background-image: url("../../../../../img/close-thick.svg");
    padding: 0;
    border-radius: 50%;
    display: none;
    z-index: 1;
    cursor: pointer;
}
.Dashboard-Board:hover .Dashboard-Board-Slot----exist .Dashboard-Board-Slot--clear{
    display: block;
}
.Dashboard-Board-Slot----exist-external:before{
    content: '';
    position: absolute;
    right: calc(100% + 4px);
    top: 0;
    bottom: 0;
    width: 8px;
    background-color: #4FAE47;
}