.Dashboard-Board-Slot--CardMedia{
    position: relative;
    padding-top: calc(100% / 600 * 270);
    z-index: 0;
}
.Dashboard-Board-Slot--CardMedia:before{
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    left: 0;
    bottom: 0;
    right: 0;
    height: 50%;
    background: linear-gradient(180deg, rgba(26, 26, 26, 0) 0%, #1A1A1A 100%);
}
.Dashboard-Board-Slot--CardMedia-category{
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 20px;
    color: #4FAE47;
    position: absolute;
    bottom: 10px;
    left: 20px
}
.Dashboard-Board-Slot--CardMedia-name{
    font-family: var(--font-family-name);
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: #FFFFFF;
    position: absolute;
    bottom: 33px;
    left: 20px;
    right: 63px;
    text-decoration: none;
}
.Dashboard-Board-Slot--CardMedia-poster{
    background-color: #eeeeee;
    position: absolute;
    z-index: -2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.Dashboard-Board-Slot--CardMedia-poster:before{
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 25%;
    z-index: 1;
    background: linear-gradient(180deg, rgba(26, 26, 26, 0) 0%, #1A1A1A 100%);
    mix-blend-mode: normal;
}
.Dashboard-Board-Slot--CardMedia-poster-img{
    width: 100%;
    height: 100%;
    display: block;
    object-position: center;
    object-fit: cover;
    z-index: 0;
}