.Topics-Single{
    display: grid;
    grid-gap: var(--font-size);
    padding: calc(var(--font-size) / 2);
    grid-template-columns: 80px 1fr;
    align-content: center;
    align-items: center;
    grid-template-rows: minmax(50px, auto);
    border-bottom: var(--input-border);
    cursor: pointer;
    text-decoration: none;
}
.Topics-Single----contains{
    background-color: #c8e6c9;
}
.Topics-Single--poster{
    background-color: var(--button-color);
    object-fit: cover;
    object-position: center;
    align-self: stretch;
    width: 100%;
}
.Topics-Single--name{
    color: var(--color-text);
    text-decoration: none;
    line-height: 1.2;
}
.Topics-Single:hover{
    background-color: var(--button-color);
}
.Topics-Single----contains:hover{
    background-color: #a5d6a7;
}

@media (max-width: 1300px) {
    .Topics-Single{
        grid-template-columns: 80px 200px;
        z-index: 5;
        background-color: #fff;
    }
}