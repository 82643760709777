.Topics{
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}


@media (max-width: 1300px) {
    .Topics{
        display: flex;
        overflow-y: hidden;
        overflow-x: scroll;
    }
}