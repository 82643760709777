.base-Authenticate{
    display: grid;
    grid-gap: var(--font-size);
    grid-template-columns: 100%;
}
.base-Authenticate--row{
    display: grid;
    grid-template-columns: 1fr 120px;
    max-width: 100%;
    width: 100%;
    grid-gap: var(--font-size);
}
.base-Authenticate--row-input{
    outline: none;
    border: none;
    background-color: var(--backgrount-color);
    border: var(--border-radius);
}