.Dashboard-Board--topics--Five{
    padding: 23px 0;
    display:grid;
    gap: 30px;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto 1fr;
    align-items: center;
}

.Dashboard-Board--topics--Five > *:first-child{
    grid-row: span 2;
    grid-column: span 2;
}