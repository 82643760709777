.base-Message{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: var(--backgrount-color-transparent);
    display: grid;
    overflow-y: auto;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    grid-template-columns: 100%;
    user-select: none;
    z-index: 1;
}
.base-Message--form{
    background-color: var(--backgrount-color);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow-transparent);
    user-select: initial;
}
.base-Message--title{
    font-size: var(--font-size);
    padding: calc((var(--font-size) * 2) - (var(--font-size) * var(--line-height))) var(--font-size);
    font-weight: bold;
    text-align: center;
    border-bottom: var(--border-hidden);
}
.base-Message--body{
    padding: var(--font-size);
}