.Dashboard-Board-Slot--LineBig{
    display: grid;
    gap: 4px;
    align-content: start;
}
.Dashboard-Board-Slot--LineBig-category{
    font-weight: bold;
    font-size: 13px;
    line-height: 20px;
    color: #4FAE47;
}
.Dashboard-Board-Slot--LineBig-name{
    font-family: var(--font-family-name);
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    color: #000000;
    text-decoration: none;
}