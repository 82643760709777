.Navigator{
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100%;
}

.Navigator--section-buttons{
    background-color: var(--color-text);
    display: grid;
    padding: calc(var(--font-size) / 2);
    gap: calc(var(--font-size) / 2);
    grid-auto-flow: column;
}
.Navigator--section-button{
    background-position: top 50% right calc(var(--font-size) / 4 * 2);
    background-repeat: no-repeat;
    background-size: var(--font-size);
    padding-right: calc(var(--font-size) / 4 * 7);
    text-align: left;
}
.Navigator--section-button--Last{
    background-image: url("../../img/page-last.svg");
}
.Navigator--section-button--Search{
    background-image: url("../../img/magnify.svg");
}
.Navigator--section-button--Externals{
    background-image: url("../../img/card-text-outline.svg");
}

.Navigator--sections{
    overflow: hidden;
}
.Navigator--section{
    display: none;
}
.Navigator--section--current{
    display: block;
    height: 100%;
}

.Navigator--bottom{
    background-color: var(--color-text);
    display: grid;
    padding: calc(var(--font-size) / 2);
    gap: var(--font-size);
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
}
.Navigator--save{
    background-repeat: no-repeat;
    background-position: top 50% right var(--font-size);
    background-size: 18px;
    background-image: url("./../../img/content-save.svg");
}
.Navigator--save--progress{
    background-image: url("./../../img/spinner.svg");
}
.Navigator--exit{
    background-repeat: no-repeat;
    background-position: top 50% right var(--font-size);
    background-size: 18px;
    background-image: url("../../img/logout-variant.svg");
}

@media (max-width: 1300px) {
    .Navigator--section-buttons{
        z-index: 1;
    }
}