@import url("./fonts/Roboto.css");
@import url("./fonts/Bitter.css");
:root{
    --font-family-name: 'Bitter', serif;
    --font-family-other: Roboto, sans-serif;
    --font-size: 16px;
    --line-height: 1.6;
    --color-text: #212121;
    --backgrount-color: #ffffff;
    --backgrount-color-transparent: #000000bb;
    --box-shadow: 0 0 5px 0 #00000088;
    --box-shadow-transparent: 0 0 5px 0 #ffffff88;
    --border-hidden: 1px solid #eeeeee;
    --border-radius: 2px;

    --border-width: 1px;
    --border-color: #BDBDBD;
    --border-color-focus: #4FC3F7;

    --button-color: #eeeeee;
    --button-color-hover: #e0e0e0;
    --button-border: var(--border-width) solid var(--border-color);

    --input-border: var(--border-width) solid var(--border-color);
    --input-background-disabled: #f5f5f5;
    --input-border-focus: var(--border-width) solid var(--border-color-focus);
    --input-focus-shadow: 0 0 calc(var(--border-width) * 4) 0px var(--border-color-focus);

    --padding-left: var(--font-size);
    --padding-top: calc((var(--font-size) * 2 - var(--font-size) * 1.6 - var(--border-width) * 2) / 2);
}

body{
    background-color: var(--backgrount-color);
}

code {
    font-family: var(--font-family-other),
    monospace;
}

body, html{
    margin: 0;
    padding: 0;
    font-family: var(--font-family-other);
}

body, html, #root{
    height: 100%;
    max-height: 100%;
    /* overflow: hidden; */
}

body,
input,
select,
button,
input[type="file"]:after,
textarea{
    font-family: var(--font-family-other);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-size: var(--font-size);
    line-height: var(--line-height);
    color: var(--color-text);
}

input:not([type]),
input[type="text"],
input[type="password"],
select,
textarea{
    border: var(--input-border);
    border-radius: var(--border-radius);
    outline: none;
    box-sizing: border-box;
    padding: var(--padding-top) var(--padding-left);
    background-color: var(--backgrount-color);
}

input:not([type]):focus:not(:disabled),
input:not([type]):hover:not(:disabled),
input[type="text"]:focus:not(:disabled),
input[type="text"]:hover:not(:disabled),
input[type="password"]:focus:not(:disabled),
input[type="password"]:hover:not(:disabled),
select:hover:not(:disabled),
select:focus:not(:disabled),
textarea:focus:not(:disabled),
textarea:hover:not(:disabled){
    border: var(--input-border-focus);
    box-shadow: var(--input-focus-shadow);
}

input:not([type]):disabled,
input[type="text"]:disabled,
input[type="password"]:disabled,
select:disabled,
textarea:disabled{
    background-color: var(--input-background-disabled);
}


input[type="file"]{
    position: relative;
    outline: none;
}
input[type="file"]:before{
    content: attr(placeholder);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
input[type="file"]:after{
    content: attr(placeholder);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

button,
input[type="reset"],
input[type="button"],
input[type="file"]:after,
input[type="submit"]{
    padding: var(--padding-top) var(--padding-left);
    background-color: var(--button-color);
    border-radius: var(--border-radius);
    border: var(--button-border);
    box-sizing: border-box;
    outline: none;
    text-align: center;
}

button:focus,
button:hover,
button.current,
input[type="reset"]:focus,
input[type="reset"]:hover,
input[type="reset"].current,
input[type="button"]:focus,
input[type="button"]:hover,
input[type="button"].current,
input[type="file"]:focus:after,
input[type="file"]:hover:after,
input[type="file"].current:after,
input[type="submit"]:focus,
input[type="submit"]:hover,
input[type="submit"].current{
    border: var(--input-border-focus);
    background-color: var(--button-color-hover);
    box-shadow: var(--input-focus-shadow);
}
.checkbox input[type="checkbox"]{
    display: none;
}
.checkbox .checkbox-placeholder{
    padding-left: 24px;
    background-position: left 0 top 50%;
    background-repeat: no-repeat;
    background-size: 20px;
    background-image: url("../../../img/checkbox-blank-outline.svg");
    cursor: pointer;
}
.checkbox input[type="checkbox"]:checked + .checkbox-placeholder{
    background-image: url("../../../img/check-box-outline.svg");
}