.Navigator-Externals-Single{
    --input-outer-height: calc(var(--padding-top) * 2 + var(--border-width) * 2);
    --inpit-line-height: calc(1em * var(--line-height));
}
.Navigator-Externals-Single--row{
    display: grid;
    gap: calc(var(--font-size) / 4);
}
.Navigator-Externals-Single--row-placeholder{
    font-size: calc(var(--font-size) * 0.8);
    font-weight: bold;
}
.Navigator-Externals-Single--row-input{
    height: calc(1 * var(--inpit-line-height) + var(--input-outer-height));
    resize: none;
}
.Navigator-Externals-Single--row-input[name="name"]{
    height: calc(2 * var(--inpit-line-height) + var(--input-outer-height));
}
.Navigator-Externals-Single--row-input[name="lead"]{
    height: calc(4 * var(--inpit-line-height) + var(--input-outer-height));
}
.Navigator-Externals-Single--row-input[name="url"]{
    height: calc(3 * var(--inpit-line-height) + var(--input-outer-height));
}
.Navigator-Externals-Single--row-file{
    display: grid;
    grid-gap: var(--font-size);
    grid-template-columns: 1fr auto;
}
.Navigator-Externals-Single--view{
    display: grid;
    grid-gap: var(--font-size);
    padding: calc(var(--font-size) / 2);
    border-bottom: var(--input-border);
    border-bottom-width: calc(var(--font-size) / 4);
    gap: calc(var(--font-size) / 2);
    grid-template-columns: 100px 1fr;
    position: relative;
}
.Navigator-Externals-Single--view-image{
    position: relative;
    min-height: calc(var(--inpit-line-height) * 3);
    font-size: 8px;
    overflow: hidden;
    z-index: 0;
}
.Navigator-Externals-Single--view-image-img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.Navigator-Externals-Single--view-description:empty{
    display: none;
}
.Navigator-Externals-Single--view-description{
    grid-column: span 2;
}
.Navigator-Externals-Single--buttons{
    display: contents;
}
.Navigator-Externals-Single--button{
    height: calc(var(--font-size));
    width: calc(var(--font-size));
    display: none;
    background-position: center;
    background-repeat: no-repeat;
    background-size: calc(var(--font-size) * 0.8);
    padding: 0;
    margin: 0;
}
.Navigator-Externals-Single--view:hover .Navigator-Externals-Single--button{
    display: block;
}
.Navigator-Externals-Single--edit{
    position: absolute;
    top: 0;
    right: 0;
    background-image: url("../../../../img/pencil.svg");
}
.Navigator-Externals-Single--remove{
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("../../../../img/close-thick.svg");
}
.Navigator-Externals-Single--form{
    width: 480px;
    display: grid;
    gap: var(--font-size)
}
.Navigator-Externals-Single--uploadError{
    display: grid;
    gap: var(--font-size);
    max-width: 480px;
}

@media (max-width: 1300px) {
    .Navigator-Externals-Single--view{
        height: 89px;
    }
    .Navigator-Externals-Single--button{
        height: 28px;
        width: 28px;
    }
    .Navigator-Externals-Single--row-input{
        height: 30px;
    }
    .Navigator-Externals-Single--row-input[name="name"]{
        height: 30px;
    }
    .Navigator-Externals-Single--row-input[name="lead"]{
        height: 30px;
    }
    .Navigator-Externals-Single--row-input[name="url"]{
        height: 30px;
    }
}